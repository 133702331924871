/* global window, rp$, rp_app */
'use strict';

const get = require('lodash/get');
// Core
require('core-js/fn/array/is-array');
require('core-js/fn/array/map');

window.rp_app = rp_app || {};


var RP_Product = function(source, data_type) {
	data_type = data_type || 'JSON';

	/* Default values for the product
	.*-----------------------------------------*/
	this.product_data = {
		attributes                  : [],
		brand                       : null,
		cart_item_id                : null,
		catalog                     : null,
		context                     : {},
		core_charge                 : 0,
		core_charge_alert           : null,
		core_charge_tooltip_link    : null,
		hazmat                      : false,
		hazmat_alert                : null,
		image_url                   : null,
		image_type                  : null,
		in_stock                    : false,
		msrp                        : 0,
		estimated_msrp              : false,
		name                        : null,
		price_is_wholesale          : false,
		quantity                    : 0,
		quantity_required           : 1,
		reference_code              : null,
		sale_price                  : 0,
		fulfillment_hash            : null,
		sku                         : null,
		sku_stripped                : null,
		total_price                 : null,
		url                         : null,
		vin                         : null,
		component_skus_stripped     : null,
		collect_vin                 : false,
		// Inventory-specific values
		inventory_type              : null,
		inventory_message           : null,
		// cart-specific values
		cart_allowed                : true,
		cart_updated                : false,
		brand_disclosure_text		: null,
		cart_source					: null,
		sold_in_pack_description    : null
	};

	if(data_type === 'DOM') {
		this.load_from_dom(source);
	} else {
		this.load_from_json(source);
	}
};

/**
* Load default product values from a DOM element (usually from Add-to-cart button)
*
* @author aspencer
* @param  DOMElement		purchaseEle		Element containing data-* attributes representing this product
* @return null
*/
RP_Product.prototype.load_from_dom = function(purchaseEle) {
	const $purchaseEle = rp$(purchaseEle);

	/* Load Product from JSON (cart object)
	.*-----------------------------------------*/
	for(let data_key in this.product_data) {
		let data_key_attr = data_key.replace(/_/g, '-');
		/* If the key and setter function exists, call it
		.*-----------------------------------------*/
		if($purchaseEle.data(data_key_attr)) {
			let setter = 'set_'+ data_key;
			let data_value = $purchaseEle.data(data_key_attr);
			if(typeof this[setter] === 'function') {
				this[setter].call(this, data_value);
			}
		}
	}

	/* Options
	.*-----------------------------------------*/
	const options = $purchaseEle.data('options');
	if(options) {
		this.set_options(options.split(','));
	}

	/* Bundle Components
	.*-----------------------------------------*/
	const componentSkusStripped = $purchaseEle.data('componentSkusStripped');
	if(componentSkusStripped) {
		this.set_component_skus_stripped(componentSkusStripped.split(','));
	}

	/* Load context from ele
	.*-----------------------------------------*/
	const contextAttrs = ['make', 'model', 'year', 'trim', 'engine', 'vin', 'group', 'subgroup', 'recommended'];
	contextAttrs.forEach((attr) => {
		if($purchaseEle.data(attr)) {
			this.add_context(attr, $purchaseEle.data(attr));
		}
	});

};

/**
* Load default product values from a JSON object (usually from cart initialization)
*
* @author aspencer
* @param  JSONObject		product_info_json		JSON object representing this product
* @return null
*/
RP_Product.prototype.load_from_json = function(product_info_json) {
	const brand = get(product_info_json, 'brand', null);  // TODO get this to work
	const catalog = get(product_info_json, 'catalog', null);
	const core_charge = get(product_info_json, 'core_charge', null);
	const core_charge_alert = get(product_info_json, 'core_charge_alert', null);
	const core_charge_tooltip_link = get(product_info_json, 'core_charge_tooltip_link', null);
	const hazmat = get(product_info_json, 'hazmat', null);
	const item_id = get(product_info_json, 'id', null);
	const image = get(product_info_json, 'image', null);
	const price = get(product_info_json, 'price', null);
	const sku = get(product_info_json, 'product_id', null);
	const quantity = get(product_info_json, 'quantity', null);
	const title = get(product_info_json, 'title', null);
	const url = get(product_info_json, 'url', null);
	const fulfillment_hash = get(product_info_json, 'fulfillment_hash', null);
	const brand_disclosure_text = get(product_info_json, 'brand_disclosure_text', null);
	const sold_in_pack_description = get(product_info_json, 'sold_in_pack_description', null);
	let sku_stripped = sku + '';
	sku_stripped = sku_stripped.replace(/[^a-z0-9]/gi,'');
	sku_stripped = sku_stripped.toLowerCase();

	/* Build option hashes from full options
	.*-----------------------------------------*/
	let options = get(product_info_json, 'options', null);
	if(Array.isArray(options)) {
		options = options.map((option) => {
			return get(option, 'option_hash');
		});
	}

	this.set_brand(brand);
	this.set_catalog(catalog);
	this.set_core_charge(core_charge);
	this.set_core_charge_alert(core_charge_alert);
	this.set_core_charge_tooltip_link(core_charge_tooltip_link);
	this.set_hazmat(hazmat);
	this.set_cart_item_id(item_id);
	this.set_image_url(image);
	this.set_sale_price(price);
	this.set_sku(sku);
	this.set_sku_stripped(sku_stripped);
	this.set_quantity(quantity);
	this.set_name(title);
	this.set_url(url);
	this.set_options(options);
	this.set_fulfillment_hash(fulfillment_hash);
	this.set_brand_disclosure_text(brand_disclosure_text);
	this.set_sold_in_pack_description(sold_in_pack_description);
};

/**
* Generate a key that is helpful in determining product uniqueness
*
* @author aspencer
* @return string  		key string
*/
RP_Product.prototype.get_cart_key = function() {
	let cartKey = [
		this.get_catalog(),
		this.get_sku(),
		this.get_options_hash()
	];
	return cartKey.join('__').toUpperCase();
};

/**
* Get an object representing this product for the shopping cart
*
* @author aspencer
* @return Object 		object representing this product
*/
RP_Product.prototype.get_cart_json = function() {

	return {
		sku: this.get_sku(),
		sku_stripped: this.get_sku_stripped(),
		cart_item_id: this.get_cart_item_id(),
		name: this.get_name(),
		price: this.get_total_sale_price(),
		quantity: this.get_quantity(),
		catalog: this.get_catalog(),
		brand: this.get_brand(),
		vin: this.get_vin(),
		image: this.get_image_url(),
		context: this.get_context(),
		options: this.get_options(),
		in_stock: this.get_in_stock(),
		fulfillment_hash: this.get_fulfillment_hash(),
		component_skus_stripped: this.get_component_skus_stripped(),
		brand_disclosure_text: this.get_brand_disclosure_text(),
		cart_source: this.get_cart_source(),
		sold_in_pack_description: this.get_sold_in_pack_description(),
	};
};

RP_Product.prototype.can_add_to_cart = function () {
	var vin_restriction = (this.get_collect_vin() && !this.get_vin());
	return (!vin_restriction && this.get_cart_allowed());
};

/********************************************
* Getters and Setters
* -----------------------------------------
* @section getters_setters
********************************************/

/* Attributes
.*-----------------------------------------*/
RP_Product.prototype.get_attributes = function() {
	return this.product_data.attributes;
};
RP_Product.prototype.set_attributes = function(attributes) {
	this.product_data.attributes = attributes;
};
RP_Product.prototype.get_options_hash = function() {
	let options = this.get_options();
	if(!Array.isArray(options)) { options = []; }
	return options.sort().join('||');
};

/* Cart Item ID (ocid)
.*-----------------------------------------*/
RP_Product.prototype.get_cart_item_id = function() {
	return this.product_data.cart_item_id;
};
RP_Product.prototype.set_cart_item_id = function(cart_item_id) {
	this.product_data.cart_item_id = cart_item_id;
};

/* Catalog
.*-----------------------------------------*/
RP_Product.prototype.get_catalog = function() {
	return this.product_data.catalog;
};
RP_Product.prototype.set_catalog = function(catalog) {
	this.product_data.catalog = catalog;
};

/* Brand
.*-----------------------------------------*/
RP_Product.prototype.get_brand = function() {
	return this.product_data.brand;
};
RP_Product.prototype.set_brand = function(brand) {
	this.product_data.brand = brand;
};

/* Context
.*-----------------------------------------*/
RP_Product.prototype.get_context = function() {
	return this.product_data.context;
};
RP_Product.prototype.set_context = function(context) {
	this.product_data.context = context;
};
RP_Product.prototype.add_context = function (attr, value) {
	this.product_data.context[attr] = value;
};

/* Core Charge
.*-----------------------------------------*/
RP_Product.prototype.get_core_charge = function() {
	return this.product_data.core_charge;
};
RP_Product.prototype.set_core_charge = function(core_charge) {
	this.product_data.core_charge =  parseFloat(core_charge);
	this.update_total_sale_price();
};

/* Core Charge Alert
.*-----------------------------------------*/
RP_Product.prototype.get_core_charge_alert = function() {
	return this.product_data.core_charge_alert;
};
RP_Product.prototype.set_core_charge_alert = function(core_charge_alert) {
	this.product_data.core_charge_alert = core_charge_alert;
};

/* Core Charge Alert Link
.*-----------------------------------------*/
RP_Product.prototype.get_core_charge_tooltip_link = function () {
	return this.product_data.core_charge_tooltip_link;
};
RP_Product.prototype.set_core_charge_tooltip_link = function (core_charge_tooltip_link) {
	this.product_data.core_charge_tooltip_link = core_charge_tooltip_link;
};


/* Hazmat
.*-----------------------------------------*/
RP_Product.prototype.get_url_group = function() {
	return this.product_data.url_group;
};
RP_Product.prototype.set_url_group = function(url_group) {
	this.product_data.url_group =  url_group;
};

/* Hazmat
.*-----------------------------------------*/
RP_Product.prototype.get_hazmat = function() {
	return this.product_data.hazmat;
};
RP_Product.prototype.set_hazmat = function(hazmat) {
	this.product_data.hazmat = (hazmat && hazmat !== '0') ? true : false;
};

/* Hazmat Part Alert
.*-----------------------------------------*/
RP_Product.prototype.get_hazmat_alert = function() {
	return this.product_data.hazmat_alert;
};
RP_Product.prototype.set_hazmat_alert = function(hazmat_alert) {
	this.product_data.hazmat_alert =  hazmat_alert;
};

/* Image URL
.*-----------------------------------------*/
RP_Product.prototype.get_image_url = function() {
	return this.product_data.image_url;
};
RP_Product.prototype.set_image_url = function(image_url) {
	this.product_data.image_url = image_url;
};

/* Image Type
.*-----------------------------------------*/
RP_Product.prototype.get_image_type = function() {
	return this.product_data.image_type;
};
RP_Product.prototype.set_image_type = function(image_type) {
	this.product_data.image_type = image_type;
};

/* MSRP
.*-----------------------------------------*/
RP_Product.prototype.get_msrp = function() {
	return this.product_data.msrp;
};
RP_Product.prototype.set_msrp = function(msrp) {
	this.product_data.msrp = msrp;
};

/* Estimated MSRP
.*-----------------------------------------*/
RP_Product.prototype.get_estimated_msrp = function() {
	return this.product_data.estimated_msrp;
};
RP_Product.prototype.set_estimated_msrp = function(estimated_msrp) {
	this.product_data.estimated_msrp = estimated_msrp;
};

/* Name
.*-----------------------------------------*/
RP_Product.prototype.get_name = function() {
	return this.product_data.name;
};
RP_Product.prototype.set_name = function(name) {
	this.product_data.name = name;
};

/* Name
.*-----------------------------------------*/
RP_Product.prototype.get_options = function() {
	return this.product_data.option_values;
};
RP_Product.prototype.set_options = function(option_values) {
	this.product_data.option_values = option_values;
};

/* Name
.*-----------------------------------------*/
RP_Product.prototype.get_component_skus_stripped = function() {
	return this.product_data.component_skus_stripped;
};
RP_Product.prototype.set_component_skus_stripped = function(component_skus_stripped) {
	this.product_data.component_skus_stripped = component_skus_stripped;
};

/* Price is Wholesale
.*-----------------------------------------*/
RP_Product.prototype.get_price_is_wholesale = function() {
	return this.product_data.price_is_wholesale;
};
RP_Product.prototype.set_price_is_wholesale = function(price_is_wholesale) {
	this.product_data.price_is_wholesale = (price_is_wholesale && price_is_wholesale !== '0') ? true : false;
};

/* Quantity
.*-----------------------------------------*/
RP_Product.prototype.get_quantity = function() {
	return this.product_data.quantity;
};
RP_Product.prototype.set_quantity = function(quantity) {
	quantity = parseInt(quantity);
	this.product_data.quantity = (isNaN(quantity) || quantity < 0) ? 0 : quantity;
	this.update_total_price();
};
RP_Product.prototype.add_quantity = function(quantity) {
	if(!this.product_data.quantity) {
		this.product_data.quantity = 0;
	}

	this.product_data.quantity += parseInt(quantity);
	this.update_total_price();
};

/* Quantity Required (For assembly)
.*-----------------------------------------*/
RP_Product.prototype.get_quantity_required = function() {
	return this.product_data.quantity_required;
};
RP_Product.prototype.set_quantity_required = function(quantity_required) {
	quantity_required = parseInt(quantity_required);
	this.product_data.quantity_required = (isNaN(quantity_required)) ? 1 : quantity_required;
};

/* Reference Code
.*-----------------------------------------*/
RP_Product.prototype.get_reference_code = function() {
	return this.product_data.reference_code;
};
RP_Product.prototype.set_reference_code = function(reference_code) {
	this.product_data.reference_code = reference_code;
};

/* Sale Price
.*-----------------------------------------*/
RP_Product.prototype.get_sale_price = function() {
	return this.product_data.sale_price;
};
RP_Product.prototype.set_sale_price = function(sale_price) {
	this.product_data.sale_price = parseFloat(sale_price);
	this.update_total_sale_price();
};

RP_Product.prototype.get_sold_in_pack_description = function() {
	return this.product_data.sold_in_pack_description;
};

RP_Product.prototype.set_sold_in_pack_description = function(sold_in_pack_description) {
	this.product_data.sold_in_pack_description = sold_in_pack_description;
};

/* Product ID
.*-----------------------------------------*/
RP_Product.prototype.get_sku = function() {
	return this.product_data.sku;
};

RP_Product.prototype.get_brand_disclosure_text = function() {
	return this.product_data.brand_disclosure_text;
};

RP_Product.prototype.set_brand_disclosure_text = function(brand_disclosure_text) {
	this.product_data.brand_disclosure_text = brand_disclosure_text;
};
RP_Product.prototype.set_sku = function(sku) {
	this.product_data.sku = sku;
};

/* Product ID
.*-----------------------------------------*/
RP_Product.prototype.get_sku_stripped = function() {
	return this.product_data.sku_stripped;
};
RP_Product.prototype.set_sku_stripped = function(sku) {
	this.product_data.sku_stripped = sku;
};

/* Total Price
.*-----------------------------------------*/
RP_Product.prototype.get_total_price = function() {
	if(!this.product_data.total_price) {
		this.update_total_price();
	}
	return this.product_data.total_price;
};
RP_Product.prototype.update_total_price = function() {
	if(this.product_data.total_sale_price && this.product_data.quantity !== undefined) {
		this.product_data.total_price = this.product_data.total_sale_price * this.product_data.quantity;
	} else {
		this.product_data.total_price = 0;
	}
};
/* Total Sale Price
.*-----------------------------------------*/
RP_Product.prototype.get_total_sale_price = function() {
	return this.product_data.total_sale_price;
};
RP_Product.prototype.update_total_sale_price = function() {
	let total_sale_price = this.get_sale_price() + this.get_core_charge();
	this.product_data.total_sale_price = parseFloat(total_sale_price);
	this.update_total_price();
};

/* URL
.*-----------------------------------------*/
RP_Product.prototype.get_url = function() {
	return this.product_data.url;
};

RP_Product.prototype.set_url = function(url) {
	this.product_data.url = url;
};

/* VIN
.*-----------------------------------------*/
RP_Product.prototype.get_vin = function() {
	return this.product_data.vin;
};
RP_Product.prototype.set_vin = function(vin) {
	this.product_data.vin = vin;
};




/* Collect VIN
.*-----------------------------------------*/
RP_Product.prototype.get_collect_vin = function() {
	return this.product_data.collect_vin;
};
RP_Product.prototype.set_collect_vin = function(collect_vin) {
	this.product_data.collect_vin = (collect_vin && collect_vin !== '0') ? true : false;
};

/* Lead Gen Supplier Hash
.*-----------------------------------------*/
RP_Product.prototype.get_fulfillment_hash = function () {
	return this.product_data.fulfillment_hash;
};

RP_Product.prototype.set_fulfillment_hash = function (fulfillment_hash) {
	this.product_data.fulfillment_hash = fulfillment_hash;
};

/* Cart source
.*-----------------------------------------*/
RP_Product.prototype.get_cart_source = function () {
	return this.product_data.cart_source;
};
RP_Product.prototype.set_cart_source = function (cart_source) {
	this.product_data.cart_source = cart_source;
};

/********************************************
* Inventory-Specific Values
* -----------------------------------------
* @section inventory_values
********************************************/

/* Inventory Message Type
.*-----------------------------------------*/
RP_Product.prototype.get_inventory_type = function() {
	return this.product_data.inventory_type;
};
RP_Product.prototype.set_inventory_type = function(inventory_type) {
	this.product_data.inventory_type =  inventory_type;
};

/* Inventory Message
.*-----------------------------------------*/
RP_Product.prototype.get_inventory_message = function() {
	return this.product_data.inventory_message;
};
RP_Product.prototype.set_inventory_message = function(inventory_message) {
	this.product_data.inventory_message =  inventory_message;
};

/* In stock
.*-----------------------------------------*/
RP_Product.prototype.get_in_stock = function() {
	return this.product_data.in_stock;
};
RP_Product.prototype.set_in_stock = function(in_stock) {
	this.product_data.in_stock =  in_stock;
};


/********************************************
* Cart-Specific Values
* -----------------------------------------
* @section cart_values
********************************************/

/* Is Allowed in Cart
.*-----------------------------------------*/
RP_Product.prototype.get_cart_allowed = function() {
	return (this.product_data.cart_allowed && this.get_total_price() > 0);
};
RP_Product.prototype.set_cart_allowed = function(cart_allowed) {
	this.product_data.cart_allowed = (cart_allowed && cart_allowed !== '0') ? true : false;
};
/* Is Updated (cart property)
* Whether or not the cart item is new or updated
.*-----------------------------------------*/
RP_Product.prototype.get_cart_updated = function() {
	return this.product_data.cart_updated;
};
RP_Product.prototype.set_cart_updated = function(cart_updated) {
	this.product_data.cart_updated = (cart_updated && cart_updated !== '0') ? true : false;
};


module.exports = RP_Product;
