'use strict';

import './bulk_product_upload.scss';

const csvFile = require('public/js/helpers/csv-file');
const { cartCache } = require('public/js/components/cart');

var THE_SCRIPT = function () {
    const $onReady = require('public/js/lib/on-ready');

    (function BulkProductUploadModule() {
        let $bulkProductUploadModal,
            $fileUploadWrapperArea,
			$modalBody,
			$modalFooter,
			$bulkOrderFile,
            uploadedFile = null;
        const MAX_ITEMS_CSV = 50;
 
        function showMessageInModal(_type,_message){
            $('.custom-alert-banner').remove();
            let _type_alert = "", _icon = "";

            switch(_type){
                case 'ok':
                    _type_alert = "alert-ok";
                    _icon = "fa-check-circle";
                    break;  
                case 'warning':
                    _type_alert = "alert-warning";
                    _icon = "fa-exclamation-circle";
                    break;  
                case 'error':
                    _type_alert = "alert-danger";
                    _icon = "fa-exclamation-triangle";
                    break;
            }
            const messageHTML = ("<div class=\"custom-alert-banner alert "+_type_alert+"\" role=\"alert\"><i class=\"fa "+_icon+"\"></i>" + _message + "</div>");
            const fileUploadWrapper = $(".modal-body p:first");
            fileUploadWrapper.before(messageHTML);
            $('html, body').animate({
                scrollTop: fileUploadWrapper.offset().top - 10
            }, 1000);
        }

        function processFile(file) {
			removeFile();
            if (file.type !== 'text/csv') {
                showMessageInModal('error','Please upload a .csv file.');
                return;
            }

            uploadedFile = file;
            displayUploadedFile();
        }

        function removeFile() {
            uploadedFile = null;
            displayUploadedFile();
            $('#bulkOrderFile').val('');
        }

        function displayUploadedFile() {
            $('.custom-alert-banner').remove();
            var uploadedFileDisplay = $('.uploaded-file-display');
            if (uploadedFile) {
                uploadedFileDisplay.html(`
				<button class="btn-remove-file">
					<i class="fa fa-times"></i>
				</button>
				<p>${uploadedFile.name}</p>
                `);
                uploadedFileDisplay.addClass('visible');
            } else {
                uploadedFileDisplay.html('');
                uploadedFileDisplay.removeClass('visible');
            }
        }

        function updateErrors(filename, newError){
            if (typeof newError === 'undefined') {
                console.log("ERROR NOT DEFINED");
            } else {
                let errorArray = JSON.parse(sessionStorage.getItem('Error')) || [];
                let resol = {};
                resol[filename] = (newError);
                errorArray.push(resol);
                sessionStorage.setItem('Error', JSON.stringify(errorArray));
            }
        }  

        function isEmpty(value) {
            if (!Array.isArray(value) || value.length === 0 || value[0] === '') {
                return true;
            }
            return false;
        }

        function isReachCSVLimit(value) {
            if (value.length > MAX_ITEMS_CSV) {
                return true;
            }
            return false;
        }

        function readAndDisplayCSVFile(file) {
            
            let separator =  ',';
            const reader = new FileReader();
            reader.onload = function (e) {
                const csvContent = e.target.result;
                const csvData = csvContent.split(/\n/);
                const headers = csvData[0].split(separator);
                const rows = [];

                for (let i = 1; i < csvData.length; i++) {
                    if(csvData[i].trim() !== ''){
                        // console.log("csvData", [csvData[i]]);

                        const row = csvData[i].split(separator);
                        const isEmptyRow = row.every(element => element === "");
                        if (!isEmptyRow) {
                            const trimmedRow = row.map(element => element.trim());
                            rows.push(trimmedRow);
                        }
                    }
                }

                if(!isEmpty(rows)){
                    if(!isReachCSVLimit(rows)){
                        rp$('body').overlaySpinner();
                        rp$('.spinner-overlay').css('position', 'fixed');
                        rp$('.spinner-icon').css('position', 'fixed');
                        $ajax({
                            method: 'POST',
                            url: '/ajax/cart/bulk-upload',
                            data:  JSON.stringify(
                                { 
                                "headers":headers,
                                "items":rows
                            }),
                            dataType: 'json',
                            contentType: 'application/json',
                            error: (jqxhr, textStatus) => {
                                rp$('body').overlaySpinner.remove();
                                showMessageInModal('error','Something fails when we\'re processing your file.');
                            },
                            success: (response, return_data) => {
                                cartCache.clear();
                                rp$('body').overlaySpinner.remove();
                                if(!isEmpty(response._result_items.ERROR)){
                                    showMessageInModal('warning','Some items from '+file.name+' were not available.'); 
                                    updateErrors(file.name, response._result_items.ERROR);
                                } else {
                                    showMessageInModal('ok','All items from '+file.name+' were added successfully.');
                                }
                                setTimeout(function() {
                                    rp_app.location.redirect('/cart');
                                }, 100);
                            }
                        });
                    } else {
                        showMessageInModal('error','The file "'+file.name+'" contains more than '+MAX_ITEMS_CSV+' items');
                    }
                } else {
                    showMessageInModal('error','The file "'+file.name+'" is empty/null');
                }
            };
            reader.readAsText(file);
        }

        function processBrandsDownload(brands) {
            const brandsCSV = brands.map(brand => [brand]);
            const encodedUri = csvFile.create(brandsCSV);
            const link = document.createElement("a");
            link.setAttribute("href", encodedUri);
            link.setAttribute("download", "brands.csv");
            document.body.appendChild(link);

            link.click();
        }

        function getBrands() {
            $ajax({
                method: 'GET',
                dataType: 'json',
                url: '/ajax/bulk-upload/brands',
                error: (jqxhr, textStatus) => {
                    if (textStatus === 'timeout') {
                        this.tryCount++;
                        if (this.tryCount <= this.maxRetry) {
                            $ajax(this);
                            return;
                        }
                    }
                },
                success: (response, status) => {
                    if(status !== 'success') { return; }
                    processBrandsDownload(response);
                }
            });
        };

        $onReady({
            rp$, window,
            label: 'bulk_product_upload',
            fn: function () {
                $bulkProductUploadModal = rp$('.bulk-product-upload-modal');
                $fileUploadWrapperArea = rp$('.file-upload-wrapper');
				$modalBody = rp$('.modal-body');
				$modalFooter = rp$('.modal-footer');
				$bulkOrderFile = rp$('#bulkOrderFile');
				const $bulkUploadButton = rp$('.bulk-product-upload-button-link');

				$bulkUploadButton.on('click', function (e) {
					e.preventDefault();
					const $bulkUploadButton = e.target.closest('.bulk-product-upload-button-link');
					if ($bulkUploadButton) {
						const uniqueId = $bulkUploadButton.getAttribute('data-unique-id');
						const isDealerSelected = $bulkUploadButton.hasAttribute('data-dealer-selected');
						let modalId;

						if (!isDealerSelected) {
							modalId = `#bulk-upload-error-popup`;
						} else {
							modalId = `#bulkProductUploadModal-${uniqueId}`;
						}
						rp$(modalId).modal();
					}
				});

                $fileUploadWrapperArea.on('dragover', function (e) {
                    e.preventDefault();
                    $(this).addClass('bulk-product-upload-active');
                });

                $fileUploadWrapperArea.on('dragleave', function (e) {
                    e.preventDefault();
                    $(this).removeClass('bulk-product-upload-active');
                });

                $fileUploadWrapperArea.on('drop', function (e) {
                    e.preventDefault();
                    $(this).removeClass('bulk-product-upload-active');
                    let files = e.originalEvent.dataTransfer.files;
                    if (files.length > 0) {
                        processFile(files[0]);
                    }
                });

                $bulkOrderFile.on('change', function (e) {
                    if (e.target.files.length > 0) {
                        processFile(e.target.files[0]);
                    }
                });

                $modalFooter.on('click', '.btn-add-to-cart', function () {
                    if (uploadedFile) {
                        readAndDisplayCSVFile(uploadedFile);
                    } else {
                        showMessageInModal('error','Please upload a file and try again');
						return;
                    }
                });

                $modalBody.on('click', '.btn-remove-file', function () {
                    removeFile();
                });

                $bulkProductUploadModal.on('click', '.btn-cancel', function () {
                    removeFile();
                });

                $bulkProductUploadModal.on('hidden.bs.modal', function () {
                    removeFile();
                });

                $modalFooter.on('click', '.brands-link', function () {
                    getBrands();
                });
            }
        });
    })();
};

try {
    __CURRENT_SCRIPT__ = THE_SCRIPT;
} catch (e) {
    THE_SCRIPT();
}
