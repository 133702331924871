/* global window, rp$, rp_app */
/* eslint no-console: ["error", { allow: ["warn", "error", "info"] }] */
'use strict';

/**
* Cart
* @author aspencer
* @dependies: jquery
*/
require('core-js/fn/array/is-array');
require('core-js/fn/array/for-each');

const debounce = require('lodash/debounce');
const get = require('lodash/get');

const dust = require('public/js/lib/dust');
const { trackCheckoutEvent, sendAdobeDTMEvent } = require('public/js/lib/adobe-dtm-helper');
const RP_Product = require('public/js/components/product');

const add_dialog_templ = require('public/js/templates/cart/add-dialog.dust');
const add_dialog_inner_templ = require('public/js/templates/cart/add-dialog-inner.dust');
const cart_templ = require('public/js/templates/cart/cart.dust');
const cart_promo_templ = require('public/js/templates/cart/cart-promo.dust');
const collect_vin_templ = require('public/js/templates/cart/collect-vin.dust');
const utils = require('public/js/helpers/utils');
const numbro = require('numbro');
const paypal = require('public/js/components/paypal');
const paypalSmartButtons = require('./paypal-smart-buttons');

const { setItem, getItem, removeItem } = require('public/js/lib/local-storage');
const CART_CACHE_KEYS = {
	totalItems: '__cart_total_items__',
	items: '__cart_items__',
	cartId: '__cart_id__',
	content: '__cart_content__',
};

const ERRORS = {
	types: {
		error: 'error',
		warning: 'warning',
	},
	codes: {
		single_warehouse_cart_restriction: 15104,
		single_warehouse_no_brand_support: 15105,
		cart_full: 15108,
		cart_missing_required_supplier_for_leadgen: 15109,
	},
};

require('public/css/components/add-to-cart.scss');


window.rp_app = rp_app || {};

/**
* RP Cart Constructor
*
* @author aspencer
* @return null
*/
var RP_Cart = function () {
	this._cart_ele_id = 'rp_global_add_to_cart';
	this._cart_ele = null;
	this._vin_ele_id = 'rp_global_collect_vin';
	this._vin_ele = null;
	this._currency_symbol = window.CurrencySymbol || '$';
	this._currency_code = window.CurrencyCode || '';
	this._checkout_url = window.CartCheckoutUrl || '';
	this._current_product = null;
	this._punchout_session = window.punchout_session;
	this._show_checkout = true;
	this._start_checkout_url = null;
	this._promo_code_from_cart = null;
	this._prevent_checkout = (typeof rp$('.cart-flyout .cart-listing').data('prevent-checkout') !== 'undefined');
	this._store_closed = (typeof rp$('.cart-flyout .cart-listing').data('store-closed') !== 'undefined');
	this._smart_buttons_enabled = (typeof rp$('.cart-flyout .cart-listing').data('smart-buttons-enabled') !== 'undefined');
	this._paypal_ec_enabled = (typeof rp$('.cart-flyout .cart-listing').data('paypal-ec-enabled') !== 'undefined');
	this._paypal_ec_api_token = rp$('.cart-flyout .cart-listing').data('paypal-ec-api-token');
	this._paypal_ec_api_env = rp$('.cart-flyout .cart-listing').data('paypal-ec-api-env');
	this._adobeDTMCartAddEvent = null;
	this.displayBrandDisclosure = false;
	this.displayBrandDisclosure = rp$('.cart-flyout .cart-listing').toArray().some(element => rp$(element).data('brand-disclosure') !== undefined);
	
	/* Debug Mode
	.*------------------------------------------*/
	this.debug_mode = false;

	/* Handle multiple attempts of save_cart in case of failure
	.*-----------------------------------------*/
	this._max_retries = 3;
	this._save_attempt_count = 0;

	/* Store the save request if we're already in the process of updating
	* Use this as a buffer to decrease server chatter on update
	.*-----------------------------------------*/
	this._current_save_request = null;
	this._waiting_save_request = null;

	/* Cart Container
	* Always loaded fresh from the server on init
	.*-----------------------------------------*/
	this.cart_id = 0;
	this.cart = [];
	this.item_count = 0;
	this.subtotal = 0;
	this._cart_product_map = {};

	// Load the cart from the global space if it exists
	// NOTE: On iframed pages, this code doesn't run (is handled by parent)
	const isIframedPage = get(rp_app, 'data.plugin_iframe_page') || false;
	if (!isIframedPage) {
		if (window.rp_cart) {
			this.init_cart(window.rp_cart);
		}
	}

	/* Create an element for vin collection & cart confirmation
	.*-----------------------------------------*/
	this._cart_ele = this.global_ele(this._cart_ele_id);
	this._vin_ele = this.global_ele(this._vin_ele_id);
};


/**
* Add a product to the cart from a DOM Element (usually an add-to-cart button)
*
* @author aspencer
* @param  DOMElement 		add_product_ele 	DOM Element containing product info as data-* attrs
* @return null
*/
RP_Cart.prototype.add_product_from_ele = function (add_product_ele, callback_object = null) {
	this.clear_current_product();
	var rp_product = new RP_Product(add_product_ele, 'DOM');
	var opts = callback_object || {}; 

	/* Check to see if a quantity box is available - set quantity from that if it exists
	.*-----------------------------------------*/
	var $quanity_input_ele = rp$(`.input-quantity[data-sku-stripped="${rp_product.get_sku_stripped()}"]`);
	if ($quanity_input_ele.length > 0) {
		rp_product.set_quantity($quanity_input_ele.val());
	}

	/* Add the item if it is allowed
	.*-----------------------------------------*/
	if (rp_product.get_cart_allowed()) {
		const newProductQuantity = rp_product.get_quantity();
		var is_updated = this.add_product(rp_product);
		rp_product.set_cart_updated(is_updated);
		this.set_current_product(rp_product);
		if (rp_product.can_add_to_cart()) {
			// Tracking -- add newest product to digitalData cart field
			const digitalDataCart = get(window, 'digitalData.products.cart');
			if (Array.isArray(digitalDataCart)) {
				digitalDataCart.push({
					'id': String(rp_product.product_data.sku_stripped),
					'name': rp_product.product_data.name,
					'brand': rp_product.product_data.brand,
					'price': String(rp_product.product_data.total_price),
					'quantity': String(rp_product.product_data.quantity)
				});
			}

			this._adobeDTMCartAddEvent = this.create_adobe_add_to_cart_event(rp_product);
			opts.previousQuantity = rp_product.get_quantity() - newProductQuantity;
			this.request_save_product(opts || null);
		}
	}
};



/**
* Adds a product element to the cart
*
* @author aspencer
* @param  RP_Product 	rp_product 	 	Product to add to the cart
* @return boolean						Whether or not the item was new (added) or existing (updated)
*/
RP_Cart.prototype.add_product = function (rp_product) {
	var is_updated = false;
	var key = rp_product.get_cart_key();

	/* Check to see if the item already exists
	.*-----------------------------------------*/
	if (this._cart_product_map[key] !== undefined) {
		// Item already exists in the cart.
		var idx = this._cart_product_map[key];
		var existing_product = this.cart[idx].product;
		// Increment the quantity of added item
		rp_product.add_quantity(existing_product.get_quantity());
		//get the disclosure
		rp_product.set_brand_disclosure_text(existing_product.get_brand_disclosure_text());

		// Pull the cart_item_id from the existing item
		rp_product.set_cart_item_id(existing_product.get_cart_item_id());
		// Replace the existing item with the new on
		// (Replace because added item is likely to be more complete)
		this.cart[idx].product = rp_product;
		is_updated = true;
	} else {
		// Item is new. Add to cart
		var new_idx = this.cart.length;
		this.cart.push({ product: rp_product });
		this._cart_product_map[key] = new_idx;
	}

	return is_updated;
};


/********************************************
* Cart Functions
* -----------------------------------------
* @section cart
********************************************/

RP_Cart.prototype.fetch_cart = function () {
	$ajax({
		type: 'GET',
		url: '/ajax/cart',
		dataType: 'json',
		data: {
			displayBrandDisclosure: this.displayBrandDisclosure ? 1 : 0,
		},
		contentType: 'application/json',
		success: (return_data/*, text_status, xhr*/) => {
			this.set_cart(return_data);
			this._checkout_url = return_data.checkoutUrl;
		}
	});
};

/**
* Initializes cart from JSON object
* usually happens as the object is being created
*
* @author aspencer
* @param  JSON object 	json_cart	Object representing cart
* @return null
*/
RP_Cart.prototype.init_cart = function (rp_cart) {
	if (this.debug_mode) {
		console.info('initial cart', rp_cart);
	}

	/* Load up the cart contents from the object
	.*-----------------------------------------*/
	this.clear_cart();
	var rp_cart_items = rp_cart.items;
	if (rp_cart_items) {
		for (var i = 0, j = rp_cart_items.length; i < j; i++) {
			var cart_item = rp_cart_items[i];

			/* Create the product object and set the quanity
			.*-----------------------------------------*/
			var product = new RP_Product(cart_item);
			var quantity = (cart_item.quantity) ? parseInt(cart_item.quantity) : 1;
			product.set_quantity(quantity);
			// Add the product to the cart
			this.add_product(product);
		}
	}

	/* Update cart totals
	.*-----------------------------------------*/
	this.update_totals();
};

/**
* Set the cart from a JSON object
* Similar to the operations in init_cart, but resets quantities instead of adding to them
* Also deletes existing items if they don't exist in input object
*
* @author aspencer
* @param  JSON object 	json_cart	Object representing cart
* @return null
*/
RP_Cart.prototype.set_cart = function (rp_cart) {
	this._currency_code = rp_cart.currencyCode || this._currency_code;

	this.cart_id = rp_cart.id;

	/* create a map of products that we add or update
	.*-----------------------------------------*/
	var _product_updated_map = {},
		key, return_rp_product, i, j, rp_idx, rp_product;

	/* Rebuild Cart items from cart response
	.*-----------------------------------------*/
	var rp_cart_items = rp_cart.items;
	if (!rp_cart_items) {
		rp_cart_items = [];
	}

	for (i = 0, j = rp_cart_items.length; i < j; i++) {
		return_rp_product = new RP_Product(rp_cart_items[i]);
		key = return_rp_product.get_cart_key();
		if (this.debug_mode) {
			console.info(key, '...maps to...', this._cart_product_map[key], 'using: ', this._cart_product_map);
		}

		/* Check to see if the item currently exists in the cart
		.*-----------------------------------------*/
		if (this._cart_product_map[key] !== undefined) {
			/* If so, set the qty and item_id from the object
			.*-----------------------------------------*/
			rp_idx = this._cart_product_map[key];
			rp_product = this.cart[rp_idx].product;
			rp_product.set_quantity(return_rp_product.get_quantity());
			rp_product.set_brand_disclosure_text(return_rp_product.get_brand_disclosure_text());
			if (return_rp_product.get_quantity() === 0) {
				rp_product.set_cart_item_id(null);
			}
			rp_product.set_cart_item_id(return_rp_product.get_cart_item_id());
			this.cart[rp_idx].product = rp_product;
		} else {
			/* Otherwise, add it
			.*-----------------------------------------*/
			this.add_product(return_rp_product);
		}


		/* Note that we have updated this product
		.*-----------------------------------------*/
		_product_updated_map[key] = true;
	}

	/* Validate Updated products against the current map of products
	* (if a product doesn't exist in updated products, it needs to be removed)
	.*-----------------------------------------*/
	for (key in this._cart_product_map) {
		if (!_product_updated_map.hasOwnProperty(key)) {
			rp_idx = this._cart_product_map[key];
			rp_product = this.cart[rp_idx].product;
			rp_product.set_quantity(0);
			rp_product.set_cart_item_id(null);
			this.cart[rp_idx].product = rp_product;
		}
	}
	
	this._promo_code_from_cart =  rp_cart.promo_code_from_cart ? rp_cart.promo_code_from_cart : null;

	/* Update cart totals
	.*-----------------------------------------*/
	this.update_totals();
};

/**
* Clear the contents of the cart
*
* @author aspencer
* @return null
*/
RP_Cart.prototype.clear_cart = function () {
	this.cart = [];
	this._cart_product_map = {};
};

/**
* Update Totals and Count from current cart object
*
* @author aspencer
* @return null
*/
RP_Cart.prototype.update_totals = function () {
	this.subtotal = 0;
	this.item_count = 0;

	for (var i = 0, j = this.cart.length; i < j; i++) {
		var cart_row = this.cart[i];
		this.subtotal += cart_row.product.get_total_price();
		let quantity = cart_row.product.get_quantity();
		// Special case for Bundle product with Master variants, when we try add to cart
		// for this product, we would add components in cart instead of the bundle sku - PRO-33
		const component_skus_stripped = cart_row.product.get_component_skus_stripped();
		if (component_skus_stripped) {
			const components_length = component_skus_stripped.length;
			quantity = components_length * cart_row.product.get_quantity();
		}
		this.item_count += quantity;
	}
};

/********************************************
* Collect VIN functionality
* -----------------------------------------
* @section collect_vin
********************************************/

RP_Cart.prototype.collect_vin = function (vin_number) {
	var rp_product = this._current_product;

	if (vin_number.length !== 17) {
		window.alert(window.javascriptContent.invalid_vin_error);
		return;
	}

	rp_product.set_vin(vin_number);
	this.close_dialog();
	this.open_cart();

	if (rp_product.can_add_to_cart()) {
		this.request_save_product();
	}
};


/********************************************
* Saving Changes to server
* -----------------------------------------
* @section saving
********************************************/

/**
* Request a save from the object, handles volume of requests from input elements
*
* @author aspencer
* @return null
*/
RP_Cart.prototype.request_save_product = function (options) {
	var opts = options || {};
	if (typeof opts.callback !== 'function') {
		opts.callback = rp$.noop;
	}

	if (this._current_save_request) {
		this._waiting_save_request = true;
	} else {
		this.save_product(opts);
	}
};

/**
* Saves the current item to the sever
*
* @author aspencer
* @return null
*/
RP_Cart.prototype.save_product = debounce(function (options) {
	this._current_save_request = true;

	var opts = options || {};
	var callback = opts.callback || rp$.noop;
	var rp_product = this._current_product;
	if (!rp_product) { return; }

	// Build update data
	var update_data = rp_product.get_cart_json();
	update_data.displayBrandDisclosure = this.displayBrandDisclosure;

	if (opts.replace) {
		update_data.replace = true;
	}

	// Add a data attr if this add to cart click was the result of a product search.
	var $addToCartBtn = rp$('.add-to-cart');
	if ($addToCartBtn && $addToCartBtn.length) {
		var fromSearch = $addToCartBtn.data('from-search');
		update_data.from_search = fromSearch;
		if (fromSearch) {
			// TODO: SMK 11/9/18 -- Potentially log events to GA here, or update the existing add to cart tracking.
		}
	}


	/* Increase the attempt count IDX
	.*-----------------------------------------*/
	this._save_attempt_count++;
	if (this.debug_mode) {
		console.info('UPDATE', update_data, JSON.stringify(update_data));
	}
	var self = this;
	const shipping_method = rp$('input[name=shipping_option]:checked');
	if(shipping_method){
		const shipping_value =shipping_method.val();
		update_data.cart_default_shipping_method =shipping_value;
	}

	if(window.tracking.google.use_gtag_admind_analytics){
		const previousQuantity = parseInt(rp$('.added_product_quantity').data('previous-value'), 10) || opts.previousQuantity || 0;
		const quantityDifference = update_data.quantity - previousQuantity;
		const operation = quantityDifference > 0 ? 'add_to_cart' : 'remove_from_cart'; 
		update_data.quantityDifference = Math.abs(quantityDifference);
		rp$('.added_product_quantity').data('previous-value', update_data.quantity);
		window.rp_app.track.track_ec_general(operation, {
			currency:this._currency_code,
			items:utils.convertNamesOfItemsForGA([update_data])
		});
	}

	$ajax({
		type: 'POST',
		url: '/ajax/cart/update',
		data: JSON.stringify(update_data),
		dataType: 'json',
		contentType: 'application/json',
		success: function (return_data/*, text_status, xhr*/) {

			self.cart_cache.store(return_data);
			if (self.debug_mode) {
				console.info('RESPONSE', return_data, JSON.stringify(return_data));
			}
			rp_app.notify.clear_notifications();
			self._show_checkout = return_data.showCheckout;
			if (return_data.checkoutUrl) {
				self._checkout_url = return_data.checkoutUrl;
			}
			if (return_data.startCheckoutUrl) {
				self._start_checkout_url = return_data.startCheckoutUrl;
			}
			if (return_data.currencyCode) {
				self._currency_code = return_data.currencyCode;
			}

			if (get(window, 'digitalData.newEvent')) {
				if (return_data.id) {
					window.digitalData.page.pageInfo.cartID = return_data.id;
				}

				// If we have a pending 'add-to-cart' event, fire it here now that we have the new cart id set
				if (self._adobeDTMCartAddEvent) {
					sendAdobeDTMEvent(self._adobeDTMCartAddEvent);
					self._adobeDTMCartAddEvent = null;
				}
			}

			/* Only rebuild totals if something has changed on the server
			 * This prevents some UX jank
			.*-----------------------------------------*/
			let current_subtotal = numbro(self.subtotal).format('0.00');
			let return_subtotal = numbro(return_data.subtotalAmount).format('0.00');
			if (!self._waiting_save_request && current_subtotal !== return_subtotal) {
				self.set_cart(return_data);
				self.update_totals();
			}

			self.build_html();

			if (return_data.inventory_message) {
				var $addToCartBtn = rp$('#added_product_img');
				var $inventoryMessage = rp$('.inventory.message');

				//Replace the HTML with the inventory message if there isn't already an inventory message in the box
				if ($addToCartBtn && $inventoryMessage && ($inventoryMessage.html() === '')) {
					// Adjust style of modal to fit the message
					$addToCartBtn.removeClass('centered');
					$inventoryMessage.html(return_data.inventory_message);
				}
			}

			if (return_data.cart_promo_message) {
				self.update_cart_promo_html(return_data.cart_promo_message);
			}

			/* Handle Errors
			.*-----------------------------------------*/
			const responseErrors = get(return_data, 'errors', []);
			const responseError = {
				type: null,
				code: null,
				message: null,
			};
			self._error_message = '';
			if (Array.isArray(responseErrors) && responseErrors.length > 0) {
				// condense any errors down to a single error response
				responseErrors.forEach(error => {
					const type = get(error, 'type', ERRORS.types.error);

					let replace = true;
					if (type === ERRORS.types.warning && responseError.type === ERRORS.types.error) {
						replace = false;
					}

					if (replace) {
						responseError.type = type;
						responseError.code = get(error, 'code', 0);
						responseError.message = get(error, 'message');
					}
				});
			}

			// display error message if it exists
			if (responseError.message && responseError.type) {
				self.empty_notifications();
				if (responseError.type === ERRORS.types.warning) {
					self._cart_ele.addClass('save_warning');
				} else {
					self._cart_ele.addClass('save_failed');
				}

				let displayMessage = responseError.message;
				switch (responseError.code) {
					case ERRORS.codes.single_warehouse_cart_restriction:
						displayMessage = window.javascriptContent.single_warehouse_cart_restriction_error;
						window.rp_app.track.track_event(['AddToCart', 'SingleWarehouseRestriction', 'DisplayMessage']);
						break;
					case ERRORS.codes.single_warehouse_no_brand_support:
						displayMessage = window.javascriptContent.single_warehouse_no_brand_support_error;
						window.rp_app.track.track_event(['AddToCart', 'SingleWarehouseRestriction', 'NoBrandSupport']);
						break;
					case ERRORS.codes.cart_full:
						displayMessage = window.javascriptContent.cart_full_error;
						window.rp_app.track.track_event(['AddToCart', 'CartFull']);
						break;
					case ERRORS.codes.cart_missing_required_supplier_for_leadgen:
						displayMessage = window.javascriptContent.cart_missing_required_supplier_for_leadgen_error;
						window.rp_app.track.track_event(['AddToCart', 'NoDealerIsAvailable']);
						break;
					default:
						displayMessage = window.javascriptContent.cart_item_add_error;
				}

				self._error_message = displayMessage;

				self.add_notification(displayMessage, responseError.type);
			} else {
				self._cart_ele.removeClass('save_failed save_warning');
			}

			/* Clear the current request and handle waiting request
			.*-----------------------------------------*/
			self._save_attempt_count = 0;
			self._current_save_request = false;
			if (self._waiting_save_request) {
				self._waiting_save_request = false;
				self.save_product(callback);
			}
			callback();
		},
		error: function (jqXHR) {


			/* Handle Retries
			.*-----------------------------------------*/
			// Reattempt if the request failed and we haven't hit the max retries
			// If a 422 error is returned, it means the update request was not valid, and we should not retry.
			if (self._save_attempt_count < self._max_retries && jqXHR.status !== 422) {
				rp_app.logger.error('Server error on cart update ... retrying (attempt: ' + self._save_attempt_count + ')', 'rp_cart.js', 245); //@fixme - set real line numbers once things stop changing
				self.save_product(callback);
			} else {
				self.build_html();

				//console.log(jqXHR, textStatus, errorThrown);
				var error = window.javascriptContent.save_cart_error;
				rp_app.logger.error('Server error on cart update ... MAX RETRIES REACHED', 'rp_cart.js', 249); //@fixme - set real line numbers once things stop changing
				self.add_notification(error, 'error');
				self._cart_ele.addClass('save_failed');

				/* Clear the current request and handle waiting request
				.*-----------------------------------------*/
				self._save_attempt_count = 0;
				self._current_save_request = false;
				// Remove the spinner overlay and call the callback
				// We will display the error message in the notification
				rp$('body').overlaySpinner.remove();

				// Only update the cart if a response exists with a cart ID and items
				// We want to update the client to match the cart's state on the server.
				var response = jqXHR.responseJSON || null;
				if (
					response &&
					get(response, 'id') &&
					get(response, 'items')
				) {
					self.set_cart(response);
					self.update_totals();
				}

				callback();
			}
		}
	});
}, 500);

/**
* Retrieve cart data and refreshes the component
*
* @author dterra
* @return null
*/
RP_Cart.prototype.load_cart = function () {
	var self = this;
	var cart_content = this.cart_cache.retrieve() || null;
	if(cart_content) {
		refreshCartUI(self, cart_content);
		rp$('.cart-flyout').overlaySpinner.remove();
		self.handle_paypal_buttons();
	} else {
		$ajax({
			type: 'GET',
			url: '/ajax/cart',
			dataType: 'json',
			data: {
				displayBrandDisclosure: self.displayBrandDisclosure ? 1 : 0,
			},
			contentType: 'application/json',
			success: (response/*, text_status, xhr*/) => {
				if (
					response &&
					get(response, 'id') &&
					get(response, 'items')
				) {
					self.cart_cache.store(response);
					refreshCartUI(self, response);
				}
				rp$('.cart-flyout').overlaySpinner.remove();
				self.handle_paypal_buttons();
			}
		});
	}
};

const refreshCartUI = (obj, cart_data) => {
	obj._checkout_url = cart_data.checkoutUrl;
	obj._show_checkout = cart_data.showCheckout;
	if (cart_data.checkoutUrl) {
		obj._checkout_url = cart_data.checkoutUrl;
	}
	if (cart_data.startCheckoutUrl) {
		obj._start_checkout_url = cart_data.startCheckoutUrl;
	}
	if (cart_data.currencyCode) {
		obj._currency_code = cart_data.currencyCode;
	}
	obj.set_cart(cart_data);
	obj.update_totals();
	obj.build_cart_html();
};

RP_Cart.prototype.handle_paypal_buttons = function () {
	rp$('.cart-component').on('click', '.paypal-button', paypal.doPaypalCheckout);
	// paypal-button flyout-action-button
	const paypalContainerClass = '.paypal-smart-flyout-button';
	paypalSmartButtons.initSmartButtons(paypalContainerClass, {
		loadingCallback: (show) => {
			if (show) {
				rp$('body').overlaySpinner();
			} else {
				rp$('body').overlaySpinner.remove();
			}
		}
	});

};


/********************************************
* HTML Rendering
* -----------------------------------------
* @section html
********************************************/

RP_Cart.prototype.open_cart = function () {
	window.tracking = window.tracking || {}; // make sure object exists
	var tracking_page = window.tracking.page || 'other';
	var rp_product = this._current_product;
	if (!rp_product) { return; }

	/* Open Dialog
	.*-----------------------------------------*/
	if (rp_product.get_collect_vin() && !rp_product.get_vin()) {
		this.open_vin_dialog();
		window.rp_app.track.track_event(['AddToCart', 'CollectVIN', tracking_page]);
	} else {
		this.open_cart_dialog();
		window.rp_app.track.track_event(['AddToCart', 'Open', tracking_page]);

		if(!window.tracking.google.use_gtag_admind_analytics) {
			const productDetails = {
				id: get(rp_product, 'product_data.sku_stripped'),
				name: get(rp_product, 'product_data.name'),
				brand: get(rp_product, 'product_data.brand'),
				price: get(rp_product, 'product_data.sale_price'),
				quantity: 1
			};
			window.rp_app.track.track_ec_cart_event('add', productDetails, this._currency_code );
		}
	}
};

RP_Cart.prototype.open_vin_dialog = function () {

	this.build_collect_vin_html();

	/* Open Vin Dialog
	.*-----------------------------------------*/

	rp$('#' + this._vin_ele_id)
		.show()
		.find('.modal')
		.modal({ show: true });
};

RP_Cart.prototype.open_cart_dialog = function () {
	//call facebook pixel tracking event
	var product_id = this._current_product.product_data.sku + '';
	product_id = product_id.replace(/[^a-z0-9]/gi, '');
	product_id = product_id.toLowerCase();
	if (typeof window.fbq !== 'undefined') {
		window.fbq('track', 'AddToCart', {
			'content_name': this._current_product.product_data.title,
			'content_ids': [product_id],
			'content_type': 'product',
		});
	}

	this.build_html();

	/* Open modal
	.*-----------------------------------------*/
	rp$('#' + this._cart_ele_id)
		.show()
		.find('.modal')
		.modal({ show: true });
};

RP_Cart.prototype.create_adobe_add_to_cart_event = function (rpProduct) {
	if (!get(window, 'digitalData.newEvent')) {
		return null;
	}

	let addToCartEvent = null;

	const productDetails = {
		id: get(rpProduct, 'product_data.sku_stripped'),
		name: get(rpProduct, 'product_data.name'),
		brand: get(rpProduct, 'product_data.brand'),
		price: get(rpProduct, 'product_data.sale_price'),
		quantity: 1
	};

	const cartItemCount = get(window, 'digitalData.page.pageInfo.cartItems', 0);
	window.digitalData.page.pageInfo.cartItems = cartItemCount + 1;

	/* Fire a different event based on whether product was
	 added from detail page or another page */
	const productPageDetails = get(window, 'digitalData.products.productDetail[0]');
	if (productPageDetails && String(productPageDetails.id) === String(productDetails.id)) {
		addToCartEvent = {
			type: 'CustomTagEvent',
			eventName: 'cartAdd',
			eventAction: 'product'
		};
	} else {
		const addToCartAttributes = {
			id: String(productDetails.id),
			name: productDetails.name,
			brand: productDetails.brand,
			price: String(productDetails.price),
			findingMethod: window.digitalData.productFindingMethod
		};

		addToCartEvent = {
			type: 'CustomTagEvent',
			eventName: 'cartAdd',
			eventAction: 'product',
			attributes: addToCartAttributes
		};
	}

	return addToCartEvent;
};

/**
* Close the Dialog Element
*
* @author aspencer
* @return null
*/
RP_Cart.prototype.close_dialog = function () {
	rp$('.modal').modal('hide');
};

RP_Cart.prototype.build_html = function () {
	this.build_dialog_html();
	this.build_cart_html();
};

RP_Cart.prototype.build_collect_vin_html = function () {
	var $ele = this._vin_ele;

	$ele.empty();

	var collect_vin_html = '';

	dust.render(collect_vin_templ, {
		product: this._current_product,
		is_webstore_plugin: rp_app.env.is_webstore_plugin,
	}, function (err, output) {
		if (err) {
			console.info(err);
		}
		collect_vin_html = output;
	});

	$ele.append(collect_vin_html);

	var self = this;
	rp$('#collect_vin_continue').on('click', function () {
		var vin_number = rp$('#collect-vin-value').val();
		self.collect_vin(vin_number);
	});
	rp$('#collect_vin_close').on('click', function () {
		self.close_dialog();
	});
};

RP_Cart.prototype.build_dialog_html = function () {

	this._cart_ele = this.global_ele(this._cart_ele_id);
	var $ele = this._cart_ele;

	this.update_totals();

	const modalExists = !!$ele.find('.modal-content').length;

	// If the modal exists we are only going to replace the content, not the entire modal
	if (modalExists) {
		$ele.find('.modal-content').empty();
	} else {
		$ele.empty();
	}

	var image_is_centered = true;
	if (this._current_product.product_data.controlled || this._current_product.product_data.inventory_message) {
		image_is_centered = false;
	}

	// Determine whether to display list price
	var display_list_price = false;
	var msrp_label = window.javascriptContent.msrp;
	var product_msrp = this._current_product.get_msrp();
	var product_price = this._current_product.get_sale_price();
	if (!this._current_product.get_estimated_msrp() && product_msrp > 0 && product_price < product_msrp) {
		display_list_price = true;
	}

	const $addToCartBtn = rp$('.add-to-cart');
	if ($addToCartBtn && $addToCartBtn.length) {
		const msrpCustomLabel = $addToCartBtn.data('msrp-custom-label');
		if (msrpCustomLabel) {
			msrp_label = msrpCustomLabel;
		}
		const displayMsrp = $addToCartBtn.data('display-msrp');
		if (displayMsrp !== null) {
			display_list_price = !!displayMsrp;
		}
	}

	//If the BS modal wrapper is not in the dom add it
	if (!modalExists) {
		dust.render(add_dialog_templ, {
			is_webstore_plugin: rp_app.env.is_webstore_plugin,
		}, function (err, output) {
			if (err) {
				console.error(err);
			}

			$ele.append(output);
		});
	}

	// Store the last focused element before opening the modal
	let lastFocusedElement = document.activeElement;

	dust.render(add_dialog_inner_templ, {
		cart_total: this.subtotal,
		cart_count: this.item_count,
		cart_items: this.cart,
		checkout_url: this._checkout_url,
		added_product: this._current_product,
		product_image_is_centered: image_is_centered,
		currency_symbol: this._currency_symbol,
		currency_code: this._currency_code,
		display_list_price: display_list_price,
		punchout_session: this._punchout_session,
		msrp_label: msrp_label,
		javascriptContent: window.javascriptContent,
		error_message: this._error_message || window.javascriptContent.cart_item_add_error,
	}, function (err, output) {
		if (err) {
			console.error(err);
		}
		var modalContent = $ele.find('.modal-content').append(output);

		// Trap focus inside the modal
		trapFocusInModal(modalContent);

		rp$('.add_cart_close').on('click', function () {
			if (lastFocusedElement) {
				// Use a slight delay to ensure the modal is fully closed and focusable elements are available
				setTimeout(function() {
					lastFocusedElement.focus();
				}, 50);  // 50ms delay should be sufficient
			}
		});
	});

	function trapFocusInModal(modal) {
		var focusableElements = modal.find('a, button, input, select, textarea, [tabindex]:not([tabindex="-1"])');
		var firstFocusableElement = focusableElements.first();
		var lastFocusableElement = focusableElements.last();
	
		// Move focus to the first focusable element when modal opens
		firstFocusableElement.focus();
	
		modal.on('keydown', function(e) {
			var isTabPressed = (e.key === 'Tab' || e.keyCode === 9);
	
			if (!isTabPressed) {
				return;
			}
	
			if (e.shiftKey) { // Shift + Tab
				if (rp$(document.activeElement).is(firstFocusableElement)) {
					e.preventDefault();
					lastFocusableElement.focus(); // Loop back to last element
				}
			} else { // Tab
				if (rp$(document.activeElement).is(lastFocusableElement)) {
					e.preventDefault();
					firstFocusableElement.focus(); // Loop back to first element
				}
			}
		});
	}

	/* Attach Events
	.*-----------------------------------------*/
	var $inner_ele = $ele.find('.dialog_wrapper');

	/* Add onchange event to quantity in dialog
	.*-----------------------------------------*/
	var self = this;

	$inner_ele.on('keydown', '.added_product_quantity', quantityKeyHandler);
	$inner_ele.on('change, keyup', '.added_product_quantity', quantityChangeHandler);
	$inner_ele.find('.added_product_quantity')[0].oninput = quantityChangeHandler;
	$inner_ele.on('focus', '.added_product_quantity', function () {
		rp$(this).data('previous-value', rp$(this).val());
	});
	/* Add Popover
	.*-----------------------------------------*/
	$inner_ele.find('.popover-tooltip').popover({
		trigger: 'hover',
		placement: 'bottom',
		// add 'template' option below to add 'rp-modal-popover', which is in turn to get around a bootstrap issue (see hacks.scss)
		template: '<div class="popover rp-modal-popover" role="tooltip"><div class="popover-arrow"></div><h3 class="popover-title"></h3><div class="popover-content"></div></div>'
	}).addClass('my-super-popover');

	function quantityKeyHandler(e) {
		// Allow arrows (37/38/39/40), home/end (35/36), enter (13), delete and backspace (46/8), TAB (9), numpad 0-9 (96-105), and 0-9 (48-57)
		if ([8, 9, 13, 35, 36, 37, 38, 39, 40, 46].indexOf(e.which) === -1 &&
			(e.which < 48 || e.which > 57) && (e.which < 96 || e.which > 105)) {
			e.preventDefault();
		}
	}
	function quantityChangeHandler(e) {
		if (e.key === 'Tab' || e.keyCode === 9) {
			return; // Exit early if Tab is pressed
		}
		var localQuantity = parseInt(rp$(this).val(), 10);
		productQuantityUpdate(localQuantity);
	}
	function checkoutClickHandler(e) {
		if(window.tracking.google.use_gtag_admind_analytics){
			var $addToCartBtn = rp$('.add-to-cart');
			window.rp_app.track.track_ec_general('begin_checkout', {
				currency:$addToCartBtn.data('currencyCode'),
				items:utils.convertNamesOfItemsForGA(utils.getItemsFromRpappCart(rp_app.cart.cart))
			});
		}
		e.preventDefault();
		trackCheckoutEvent();
		rp_app.location.redirect(self._checkout_url);
	}
	function continueClickHandler(/*e*/) {
		if (self._current_product.product_data.component_skus_stripped !== null) {
			const localQuantity = $inner_ele.find('.added_product_quantity').val();
			productQuantityUpdate(localQuantity);
			self.request_save_product();
		}
		self.close_dialog();
	}
	function replaceCartClickHandler(/*e*/) {
		window.rp_app.track.track_event(['AddToCart', 'SingleWarehouseRestriction', 'ReplaceCart']);
		self.clear_notifications();
		self.request_save_product({
			replace: true,
		});
	}

	function productQuantityUpdate(val) {
		const opts = { previousQuantity: self._current_product.get_quantity() };
		self._current_product.set_quantity(val);
		self.update_totals();
		self.build_cart_html();
		self.request_save_product(opts);
	}

	/* Click Events to go to cart page or continue shopping
	.*-----------------------------------------*/
	$inner_ele.on('click', '.add_cart_checkout', checkoutClickHandler);
	$inner_ele.on('click', '.add_cart_close', continueClickHandler);
	$inner_ele.on('click', '.replace-cart', replaceCartClickHandler);

	/* If the image is alone in its column, middle alignment
	.*-----------------------------------------*/
	// var $img_ele = $ele.find('.product_img_messages');
	// var $img_col = $ele.find('.product_img_col');
	// var $product_ele = $ele.find('.product_details_col');
	/* Doing a timeout for this check because we can't rely on load()
	window.setTimeout(function() {
	var img_calc_h = $img_ele.outerHeight() + 10;
	var ele_calc_h = $product_ele.outerHeight();
	$img_ele.height(img_calc_h);
	$img_ele.css({'position': 'absolute'});
	$img_col.height(ele_calc_h);
}, 150);
*/
};

RP_Cart.prototype.build_cart_html = function () {

	var cart_html = '';

	dust.render(cart_templ, {
		cart_id: this.cart_id,
		cart_total: this.subtotal,
		cart_count: this.item_count,
		cart_items: this.cart,
		currency_symbol: this._currency_symbol,
		currency_code: this._currency_code,
		checkout_url: this._checkout_url,
		start_checkout_url: this._start_checkout_url,
		show_checkout: this._show_checkout,
		use_product_link: true,
		prevent_checkout: this._prevent_checkout,
		store_closed: this._store_closed,
		smart_buttons_enabled: this._smart_buttons_enabled,
		paypal_ec_enabled: this._paypal_ec_enabled,
		paypal_ec_api_token: this._paypal_ec_api_token,
		paypal_ec_api_env: this._paypal_ec_api_env,
		is_webstore_plugin: rp_app.env.is_webstore_plugin,
		brand_disclosure: this.displayBrandDisclosure,
		flyout_promo_code: this._promo_code_from_cart,
		javascriptContent: window.javascriptContent,
	}, function (err, output) {
		if (err) {
			console.error(err);
		}
		cart_html = output;
	});


	var cart_count_label = (this.item_count === 1) ? 'item' : 'items';

	/* Update DOM Elements
	.*-----------------------------------------*/
	rp$('.cart-items').html(this.item_count);
	if (this.item_count > 0) {
		rp$('.cart-items').addClass('cart-items-exist');
	} else {
		rp$('.cart-items').removeClass('cart-items-exist');
	}
	rp$('.cart-items-label').html(cart_count_label);
	rp$('.cart_listing, .cart-flyout .cart-listing').html(cart_html);
};

RP_Cart.prototype.update_cart_promo_html = function (cart_promo_message) {
	let cart_promo_wrapper = rp$('.cart-promo-message-wrap');

	if (!cart_promo_wrapper.length) {
		return;
	}

	let cart_promo_html = '';

	dust.render(cart_promo_templ, {
		cart_promo_message: cart_promo_message,
	}, function (err, output) {
		if (err) {
			console.error(err);
		}
		cart_promo_html = output;
	});

	cart_promo_wrapper.html(cart_promo_html);
};

/********************************************
* Global Element Creation
* -----------------------------------------
* @section global_ele
********************************************/

RP_Cart.prototype.global_ele = function (global_ele_id) {
	var $global_ele = rp$('#' + global_ele_id);
	if ($global_ele.length === 0) {
		rp$('#rp_webstore').append('<div id="' + global_ele_id + '" style="display:none"> </div>');
		$global_ele = rp$('#' + global_ele_id);
	}
	return $global_ele;
};

/********************************************
* Notifications
* -----------------------------------------
* @section notifications
********************************************/
RP_Cart.prototype.add_notification = function (message, type) {
	var $notifications = rp$('#dialog_notification');

	message = '<p class="message">' + message + '</p>';
	$notifications.append(message);
	$notifications.addClass(type);

	if (!$notifications.is(':visible')) {
		$notifications.show('fast');
	}
};

RP_Cart.prototype.empty_notifications = function () {
	var $notifications = rp$('#dialog_notification');
	$notifications.empty();
};

RP_Cart.prototype.clear_notifications = function () {
	var $notifications = rp$('#dialog_notification');
	//var $content = rp$('#dialog_notification .container-wrap');
	$notifications.removeClass('error info warning');
	$notifications.empty();
	$notifications.hide('fast');
};

/********************************************
* Getters and Setters
* -----------------------------------------
* @section getters_setters
********************************************/

/* Current Product
.*-----------------------------------------*/
RP_Cart.prototype.set_current_product = function (rp_product) {
	this._current_product = rp_product;
};
RP_Cart.prototype.clear_current_product = function () {
	this._current_product = null;
};

/********************************************
* Validate required message
* -----------------------------------------
* @section validations
********************************************/
RP_Cart.prototype.validate_required_message = function (elemList, elemClass, action) {
	elemList.each((idx, elem) => {
		if (rp$(elem).hasClass(elemClass)) {
			if (action === 'add') {
				rp$(elem).addClass('no-option-selected');
				rp$(elem).html(rp$(elem).attr('data-content'));
			} else {
				rp$(elem).removeClass('no-option-selected');
				rp$(elem).html('');
			}
		}
	});
};

RP_Cart.prototype.cart_cache = {
	store: cart_data => setItem(CART_CACHE_KEYS.content, JSON.stringify(cart_data)),
	retrieve: () => JSON.parse(getItem(CART_CACHE_KEYS.content)),
	clear: () => removeItem(CART_CACHE_KEYS.content),
};

/********************************************
* Bind the class to rp_app.cart
* -----------------------------------------
* @section binding
********************************************/
window.rp_app.cart = new RP_Cart();


/********************************************
* Jquery Plugin for "add to cart" buttons
* -----------------------------------------
* @section jquery
********************************************/

rp$.fn.add_to_cart = function (showModal=true) {
	// Drop-down option-style: Validate required message
	const $requiredOptionSelects = rp$('#page-product .product-purchase-module').find('.option-select.option-required');
	const $requiredOptionSelectsMessageElem = rp$('#page-product .product-purchase-module').find('.select-validation-message.option-required');
	$requiredOptionSelects.on('change', function () {
		rp$(this).removeClass('no-option-selected').popover('dispose');
		const optionId = rp$(this).attr('data-option-id');
		rp_app.cart.validate_required_message($requiredOptionSelectsMessageElem, optionId, 'remove');
	});

	// Button option-style: Validate required message
	const $requiredButtonOptionSelects = rp$('#page-product .product-purchase-module').find('.option-buttons.option-required');
	const $requiredButtonMessageElem = rp$('#page-product .product-purchase-module').find('.validation-message.option-required');
	$requiredButtonOptionSelects.on('click', function () {
		const optionId = rp$(this).attr('data-option-id');
		rp_app.cart.validate_required_message($requiredButtonMessageElem, optionId, 'remove');
	});

	return this.each(function () {
		// reset previous invalid product options
		$requiredOptionSelects.removeClass('no-option-selected').popover('dispose');
		const optionId = rp$(this).attr('data-option-id');
		rp_app.cart.validate_required_message($requiredOptionSelectsMessageElem, optionId, 'remove');
		rp_app.cart.validate_required_message($requiredButtonMessageElem, optionId, 'remove');

		rp$(this).on('click', function (event) {
			event.preventDefault();
			let isValidAddToCart = true;
			// Drop-down option-style: validate required options are selected before add to cart
			const $invalidSelects = $requiredOptionSelects.filter(function () {
				return !this.value;
			});
			$invalidSelects.each((idx, selectOptionElem) => {
				const optionId = rp$(selectOptionElem).attr('data-option-id');
				// Add required message
				rp_app.cart.validate_required_message($requiredOptionSelectsMessageElem, optionId, 'add');
				// Do NOT add to cart
				isValidAddToCart = false;
			});

			// Button option-style: validate required options are selected before add to cart
			$requiredButtonOptionSelects.each((idx, buttonOptionElem) => {
				const optionId = rp$(buttonOptionElem).attr('data-option-id');
				const $buttonSelected = rp$(buttonOptionElem).find('button.selected');
				if ($buttonSelected.length === 0) {
					// Add required message
					rp_app.cart.validate_required_message($requiredButtonMessageElem, optionId, 'add');
					// Do NOT add to cart
					isValidAddToCart = false;
				}
			});

			if (!isValidAddToCart) {
				return false;
			}
			rp$('body').overlaySpinner();
			rp$('.spinner-overlay').css('position', 'fixed');
			rp$('.spinner-icon').css('position', 'fixed');
			rp_app.cart.add_product_from_ele(this, {
				callback: function () {
					rp$('body').overlaySpinner.remove();
					if(showModal) {
						rp_app.cart.open_cart();
					}
				},
			});
		});
	});
};

const attachAddToCartEvents = function (selector) {
	rp$(selector).add_to_cart();
};

const asyncCartDataLoader = function () {
	rp_app.cart.load_cart();
};

const cartCache = { 
	...rp_app.cart.cart_cache 
};

module.exports = { 
	attachAddToCartEvents,
	asyncCartDataLoader,
	cartCache
};
